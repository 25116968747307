.header {
    margin-bottom: 40px;
    border-bottom: 40px solid rgb(1, 104, 179);

    .logo {
        height: 150px;
    }

    .nav {
        display: flex;
        align-items: center;

        a {
            padding: 0 10px;
            color: #333;
            transition: all 200ms ease-in-out;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;

            &:hover {
                opacity: 0.5;
            }
        }
    }
}
