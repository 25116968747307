$titleWidth: 50px;
$firstLevelWidth: 230px;
$secondLevelWidth: 310px;

.container {
    width: 100%;
    margin: 0 auto;

    .rootContainer {
        display: flex;
        flex-direction: column;

        .category {
            display: flex;
            flex-direction: row;

            .categoryTitle {
                position: relative;
                width: $titleWidth;
                border: 2px solid #fff;

                strong {
                    color: #fff;
                    position: absolute;
                    bottom: 45%;
                    left: 50%;
                    transform: rotate(270deg) translateX(-50%) translateY(-50%);
                    transform-origin: 0 0;
                }
            }

            .firstLevelContainer {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .firstLevel {
                    display: flex;
                    flex-direction: row;

                    .firstLevelTitle {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: $firstLevelWidth;
                        border: 2px solid #fff;
                        cursor: pointer;
                    }

                    .secondLevelContainer {
                        display: flex;
                        flex-direction: column;
                        flex-grow: 1;

                        .secondLevel {
                            display: flex;
                            flex-direction: row;

                            &.hide {
                                opacity: 0;
                                cursor: default !important;

                                * {
                                    cursor: default !important;
                                }
                            }

                            .secondLevelTitle {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: $secondLevelWidth;
                                border: 2px solid #fff;
                                cursor: pointer;
                            }

                            .detailsLevelContainer {
                                display: flex;
                                flex-direction: column;
                                flex-grow: 1;

                                .details {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    width: 100%;
                                    border: 2px solid #fff;
                                    padding: 0 10px;

                                    &.hide {
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:nth-child(1) {
                .categoryTitle {
                    background-color: #003761;
                }
                .firstLevelTitle {
                    background-color: #316081;
                }
                .secondLevelTitle {
                    background-color: #7f9baf;
                }
                .details {
                    background-color: #cdd7df;
                }
            }

            &:nth-child(2) {
                .categoryTitle {
                    background-color: #57ab27;
                }
                .firstLevelTitle {
                    background-color: #8dc060;
                }
                .secondLevelTitle {
                    background-color: #b8d698;
                }
                .details {
                    background-color: #ddebce;
                }
            }

            &:nth-child(3) {
                .categoryTitle {
                    background-color: #612158;
                }
                .firstLevelTitle {
                    background-color: #834e75;
                }
                .secondLevelTitle {
                    background-color: #a8859e;
                }
                .details {
                    background-color: #d2c0cd;
                }
            }
        }
    }

    .actionContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
    }
}
