$widthFirstLevel: 422px;
$widthFirstLevelNode: 160px;
$widthSecondLevel: 211px;
$widthSecondLevelNode: 150px;
$widthThirdLevel: 200px;
$widthThirdLevelNode: 171px;
$treeNodeHeight: 80px;

.container {
    width: 100%;
    margin: 0 auto;

    .rootContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .rootNode {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 180px;
            height: 94px;
            border: 4px solid;
            box-sizing: border-box;
            border-radius: 20px;
            margin: 0 20px;
            cursor: pointer;

            &.active {
                &:after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 20px;
                    background-color: #cfd1d2;
                    bottom: -24px;
                    left: 85px;
                }
            }

            .counter {
                font-size: 24px;
            }

            &.blue {
                color: #003761;
                border-color: #003761;

                &.active {
                    background-color: #003761;
                    color: #fff;
                }
            }

            &.green {
                color: #57ab27;
                border-color: #57ab27;

                &.active {
                    background-color: #57ab27;
                    color: #fff;
                }
            }

            &.purple {
                color: #612158;
                border-color: #612158;

                &.active {
                    background-color: #612158;
                    color: #fff;
                }
            }
        }

        .btn {
            position: absolute;
            right: 0;
        }
    }

    .treeNode {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 18px;
        width: $widthFirstLevelNode;
        height: $treeNodeHeight;
        font-size: 12px;
        margin-bottom: 20px;
        cursor: pointer;

        &.blue {
            background-color: #e6ebef;
        }

        &.green {
            background-color: #f2f7ec;
        }

        &.purple {
            background-color: #ede5ea;
        }

        &.active {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }

    .firstLevelInner {
        > .treeNode {
            &.active {
                &.blue {
                    background-color: #316081;
                    color: #fff;
                }

                &.green {
                    background-color: #8dc060;
                    color: #fff;
                }

                &.purple {
                    background-color: #834e75;
                    color: #fff;
                }
            }
        }
    }

    .secondLevelInner {
        > .treeNode {
            &.active {
                &.blue {
                    background-color: #7f9baf;
                }

                &.green {
                    background-color: #b8d698;
                }

                &.purple {
                    background-color: #a8859e;
                }
            }
        }
    }

    .thirdLevel {
        > .treeNode {
            &.active {
                &.blue {
                    background-color: #cdd7df;
                }

                &.green {
                    background-color: #ddebce;
                }

                &.purple {
                    background-color: #d2c0cd;
                }
            }
        }
    }

    .treeView {
        .firstLevelLineWrapper {
            display: flex;
            flex-direction: row;
            width: 800px;
            margin: 0 auto;

            &.col-2 {
                width: 600px;

                .firstLevelLine {
                    width: 100%;
                }
            }

            &.col-3 {
                width: 800px;

                .firstLevelLine {
                    width: 50%;
                }
            }

            .firstLevelLine {
                &:not(:first-child) {
                    margin-left: -1px;
                }
            }
        }

        .firstLevelLine {
            margin-top: 20px;
            height: 20px;
            border-top: 1px solid;
            border-left: 1px solid;
            border-right: 1px solid;
            border-color: #cfd1d2;
        }

        .firstLevel {
            margin: 0 auto;
            display: flex;
            justify-content: space-around;

            .firstLevelInner {
                width: $widthFirstLevel;

                > .treeNode {
                    margin-left: auto;
                    margin-right: auto;

                    &:after {
                        opacity: 0;
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 20px;
                        background-color: #cfd1d2;
                        bottom: -20px;
                    }

                    &.active {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .secondLevelLine {
            margin: 0 auto 0 60px;
            width: 260px;
            height: 20px;
            border-top: 1px solid;
            border-left: 1px solid;
            border-right: 1px solid;
            border-color: #cfd1d2;
        }

        .secondLevel {
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .secondLevelInner {
                width: $widthSecondLevel;
                display: flex;
                flex-direction: column;

                > .treeNode {
                    width: $widthSecondLevelNode;
                }
            }
        }

        .thirdLevel {
            margin: 0 auto;
            // width: $widthThirdLevel;
            display: flex;
            flex-direction: column;

            > .treeNode {
                width: $widthThirdLevelNode;
                margin-left: 40px;

                &:before {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: #{$treeNodeHeight / 2 + 20};
                    left: -20px;
                    top: -20px;
                    border-left: 1px solid;
                    border-bottom: 1px solid;
                    border-color: #cfd1d2;
                }

                &:not(:last-child) {
                    &:after {
                        content: "";
                        position: absolute;
                        width: 20px;
                        height: #{$treeNodeHeight / 2};
                        left: -20px;
                        top: 40px;
                        border-left: 1px solid;
                        border-color: #cfd1d2;
                    }
                }
            }
        }
    }
}
