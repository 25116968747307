// Fit into website content
html,
body,
#root {
  height: 100%;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 20px;
  margin: 0;
}

a {
  text-decoration: none;
  color: #0168b3;

  &:hover {
    color: darken(#0168b3, 10%);
  }
}

strong {
  font-weight: 600;
}

// bootstrap overwrite
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 26px;
  border-radius: 6px;
  height: 60px;

  &.btn-primary {
    background: #00549f;
    color: #fff;

    &:hover {
      background: #00427b;
      color: #fff;
    }
  }
}

.modal {
  .modal-dialog {
    .modal-header,
    .modal-footer {
      border: none;
    }
  }
}
