.main {
    padding-bottom: 50px;

    img {
        width: 100%;
    }
}

.aside {
    .box {
        width: 100%;
        background-color: #e7e7e7;
        padding: 32px;
        margin-bottom: 20px;

        .spacer {
            height: 1px;
            width: 100%;
            background-color: #dc1969;
            margin: 20px 0;
        }

        p {
            strong {
                display: block;
            }
        }

        img {
            max-width: 60%;
        }
    }
}

.blueInfoBox {
    position: relative;
    width: 100%;
    padding-top: 100%;

    .content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 30px;
        color: #fff;
        background-color: #407fb7;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.greyArrow {
    position: relative;
    width: 100%;
    padding-top: 100%;

    svg {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
    }

    .content {
        position: absolute;
        top: 28%;
        bottom: 30%;
        left: 30px;
        right: 20%;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
