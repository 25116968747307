.container {
    width: 100%;
    margin: 0 auto;

    .rootContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .rootNode {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 180px;
            height: 94px;
            border: 4px solid;
            box-sizing: border-box;
            border-radius: 20px;
            margin: 0 20px;
            cursor: pointer;

            .counter {
                font-size: 24px;
            }

            &.blue {
                color: #003761;
                border-color: #003761;

                &.active {
                    background-color: #003761;
                    color: #fff;
                }
            }

            &.green {
                color: #57ab27;
                border-color: #57ab27;

                &.active {
                    background-color: #57ab27;
                    color: #fff;
                }
            }

            &.purple {
                color: #612158;
                border-color: #612158;

                &.active {
                    background-color: #612158;
                    color: #fff;
                }
            }
        }
    }

    .iopContainer {
        width: calc(100% - 200px);
        margin: 20px 100px;
    }

    .actionContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .tableContainer {
        width: calc(100% - 200px);
        margin: 20px 100px;

        table {
            tr {
                th,
                td {
                    border: 1px solid black;
                    vertical-align: top;
                    padding: 10px;

                    &:nth-child(1) {
                        width: 110px;
                    }
                }
            }
        }
    }
}
