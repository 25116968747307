.footer {
    color: #fff;
    background-color: #333333;
    margin-top: 40px;
    padding: 40px;

    a {
        color: #fff;
    }

    .social {
        margin: 10px 0;

        a {
            padding: 5px;
        }
    }
}
